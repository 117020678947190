<template>
  <div class="intro row">
    <div class="col-12 col-xl-4">
      <div class="bg-white h-100 p-4">
        <div class="mb-5">
          <p class="intro-title text-xl"><img src="@/assets/image/ai/quote.png" alt="">服務特色</p>
          <p class="intro-content text-lg">AI 小幫手整合最先進 AI 語言處理技術，透過智能化的演算法，協助您快速生成商用日文書信與日文演講稿，無論您需要迅速回應客戶、撰寫各種商業書信，還是準備下一場重要的演說，AI 小幫手都能成為最佳輔助工具，讓您的商務溝通更為流暢並提高工作效率。</p>
        </div>
        <div>
          <p class="intro-title text-xl"><img src="@/assets/image/ai/quote.png" alt="">備註</p>
          <p class="intro-content text-lg">使用時，請確保您輸入的資訊正確完整，這有助於 AI 小幫手生成最適合您需求的內容。同時，請記得審慎修改及校對最終產出的文件，確保符合您的期望和準確性。</p>
        </div>
      </div>
    </div>
    <div class="illustrate col-12 col-xl-8 text-lg mt-3 mt-xl-0">
      <div class="intro-banner text-white py-4 text-xl mb-4">
        <p>使用說明</p>
      </div>
      <div class="d-flex flex-wrap">
        <div class="step step1 py-3 d-flex align-items-center justify-content-center">
          <p>選擇類型</p>
        </div>
        <div class="intro-text bg-white py-3 flex-1 px-3">
          <p>請在左側選擇您需要生成的內容</p>
        </div>
      </div>
      <div class="arrow my-2 mx-auto mx-sm-0">
        <img src="@/assets/image/ai/Arrow1.png" alt="" class="mx-auto d-block">
      </div>
      <div class="d-flex flex-wrap">
        <div class="step step2 py-3 d-flex align-items-center justify-content-center">
          <p>填寫資料</p>
        </div>
        <div class="intro-text bg-white py-3 flex-1 px-3">
          <p>請在指定欄位中填入相關資訊後點擊START送出，請稍作等待，由AI小幫手為您完成目標任務。</p>
        </div>
      </div>
      <div class="arrow my-2 mx-auto mx-sm-0">
        <img src="@/assets/image/ai/Arrow2.png" alt="" class="mx-auto d-block">
      </div>
      <div class="d-flex flex-wrap">
        <div class="step step3 py-3 d-flex align-items-center justify-content-center">
          <p>查看成果</p>
        </div>
        <div class="intro-text bg-white py-3 flex-1 px-3">
          <p>AI小幫手撰寫完畢後，請再次閱讀所生成內容，確認是否符合您的需求。</p>
        </div>
      </div>
      <div class="arrow my-2 mx-auto mx-sm-0">
        <img src="@/assets/image/ai/Arrow3.png" alt="" class="mx-auto d-block">
      </div>
      <div class="d-flex flex-wrap">
        <div class="step step4 py-3 d-flex align-items-center justify-content-center">
          <p>更多功能</p>
        </div>
        <div class="intro-text bg-white py-3 flex-1 px-3">
          <p>歡迎點擊字典查詢、中文翻譯、全文播放、字級大小等延伸功能進行進一步學習。若想獲得更多靈感，請嘗試重新生成。</p>
        </div>
      </div>
      <!-- <div class="arrow my-2">
        <img src="@/assets/image/ai/Arrow4.png" alt="" class="mx-auto d-block">
      </div> -->
      <!-- <div class="d-flex">
        <div class="step step5 py-3">
          <p>更多功能</p>
        </div>
        <div class="intro-text bg-white py-3 flex-1 px-3">
          <p>想要得獲得更多靈感，請嘗試重新生成；更提供字典查詢、全文播放功能進行自我練習。</p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
  export default {
    name: 'intro'
  }
</script>
<style lang="scss" scoped>
  .intro {
    @media (min-width: 640px) {
      padding: 1.5rem !important;
    }
    @media (min-width: 640px) {
      padding: 1.5rem !important;
    }
  }
  .intro-title {
    line-height: 2;
    color: #F30000;
    font-weight: 900;
    @media (max-width: 1024px) {
      font-size: 1.125rem;
    }
  }
  .intro-content {
    line-height: 1.8;
    color: #1A1A1A;
    font-weight: 600;
    @media (max-width: 1024px) {
      font-size: 1.125rem;
    }
  }
  .intro-banner {
    background: #0E103E;
    text-align: center;
    // margin-bottom: 2rem;
    font-weight: 700;
    background-image: url('~@/assets/image/ai/plusB.png'), url('~@/assets/image/ai/plusS.png'), url('~@/assets/image/ai/plusB.png'), url('~@/assets/image/ai/plusS.png');
    background-repeat: no-repeat;
    background-position: left 10% bottom 20%, left 5% top 20%, right 10% bottom 20%, right 5% top 20%;
    p {
      margin: 0;
    }
    @media (max-width: 1024px) {
      font-size: 1.125rem;
    }
  }
  .illustrate {
    font-weight: 600;
    .arrow {
      width: 20%;
    }
    .step {
      width: 20%;
      @media (max-width: 575px) {
        width: 100%;
      }
    }
    .step1 {
      background-color: #252F82;
      p {
        text-align: center;
        color: white;
      }
    }
    .step2 {
      background-color: #642582;
      p {
        text-align: center;
        color: white;
      }
    }
    .step3 {
      background-color: #9B128D;
      p {
        text-align: center;
        color: white;
      }
    }
    .step4 {
      background-color: #D33F2A;
      p {
        text-align: center;
        color: white;
      }
    }
    .step5 {
      background-color: #F96F34;
      p {
        text-align: center;
        color: white;
      }
    }
    .intro-text{
      text-align: center;
      border-top: 1px solid #0E103E;
      border-bottom: 1px solid #0E103E;
      p {
        text-align: left;
        margin: 0;
        @media (max-width: 1024px) {
          font-size: 1.125rem;
        }
      }
    }
  }

</style>
